import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs/operators';
import { Transit } from '@app/store/transits/models';
import {
    selectOrder,
    selectLoaded,
    selectLoadedErrorCode,
    selectLoading,
    selectUpdated,
    selectUpdatedErrorCode,
    selectUpdating,
    selectSaved,
    selectSavedErrorCode,
    selectSaving,
} from '../selectors/order.selector';
import { orderActions } from '../actions/order.actions';
import { OrderDraftValue, Order } from '../models';
import { OrderUpdateValue } from '../models/order-update.value';

@Injectable({
    providedIn: 'root',
})
export class OrderFacade {
    private store = inject(Store);

    readonly order$ = this.store.select(selectOrder).pipe(filter((order) => order !== null));

    readonly loaded$ = this.store.select(selectLoaded).pipe(filter((loaded) => loaded !== null));
    readonly loadedError$ = this.store.select(selectLoadedErrorCode).pipe(filter((error) => error !== null));
    readonly loading$ = this.store.select(selectLoading);

    readonly updated$ = this.store.select(selectUpdated).pipe(filter((updated) => updated !== null));
    readonly updatedError$ = this.store.select(selectUpdatedErrorCode).pipe(filter((error) => error !== null));
    readonly updating$ = this.store.select(selectUpdating);

    readonly saved$ = this.store.select(selectSaved).pipe(filter((saved) => saved !== null));
    readonly savedError$ = this.store.select(selectSavedErrorCode).pipe(filter((error) => error !== null));
    readonly saving$ = this.store.select(selectSaving);

    get order(): Order | null {
        let response: Order | null = null;

        this.store
            .select(selectOrder)
            .pipe(
                tap((value) => (response = value)),
                take(1),
            )
            .subscribe();

        return response;
    }

    createDraft(value: OrderDraftValue, transitFrom: Transit, transitTo?: Transit): void {
        this.store.dispatch(orderActions.createOrder({ value, transitFrom, transitTo }));
    }

    getOrder(id: string) {
        this.store.dispatch(orderActions.getOrder({ id }));
    }

    getOrderByToken(id: string, token: string) {
        this.store.dispatch(orderActions.getOrderByToken({ id, token }));
    }

    updateOrder(id: string, value: OrderUpdateValue) {
        this.store.dispatch(orderActions.updateOrder({ id, value }));
    }

    submitOrder(orderId: string, paymentId: string, transactionId: string | null) {
        this.store.dispatch(orderActions.submitOrder({ orderId, paymentId, transactionId }));
    }

    clear(): void {
        this.store.dispatch(orderActions.clear());
    }
}
