import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ORDER_FEATURE_KEY } from './keys';
import { orderReducer } from './reducers/order.reducer';
import { OrderEffects } from './effects/order.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(ORDER_FEATURE_KEY, orderReducer), EffectsModule.forFeature([OrderEffects])],
})
export class OrderStoreModule {}
