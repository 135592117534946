import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ApiResponse } from '@app/shared/models';
import { AuthService } from '@app/core/auth';
import { catchError, map, mergeMap, Observable } from 'rxjs';
import { Transit } from '@app/store/transits/models';
import { OrderDraftValue, DraftValueDto, Order, OrderDto, OrderUpdateValue, OrderUpdateValueDto, OrderPayment, OrderPaymentDto, ErrorCreate } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private readonly httpClient = inject(HttpClient);
    private authService = inject(AuthService);

    createOrder(value: OrderDraftValue, transitFrom: Transit, transitTo?: Transit): Observable<{ id: string }> {
        const params = new DraftValueDto(value, transitFrom, transitTo);

        const httpRequest = this.httpClient
            .addServer()
            .skipErrorHandler()
            .post<ApiResponse<{ id: string }>>('api/order', { ...params })
            .pipe(
                map((response) => response.data),
                catchError((data: HttpErrorResponse | unknown) => {
                    throw new ErrorCreate(data);
                }),
            );

        return this.authService.refresh().pipe(
            mergeMap(() => httpRequest),
            catchError(() => httpRequest),
        );
    }

    getOrder(id: string): Observable<Order> {
        return this.httpClient
            .addServer()
            .skipErrorHandler()
            .skipRefreshToken()
            .addRefreshTokenSilent()
            .get<ApiResponse<OrderDto>>(`api/order/${id}`)
            .pipe(map((response) => new Order(response.data)));
    }

    getOrderByToken(id: string, token: string): Observable<Order> {
        return this.httpClient
            .addServer()
            .skipErrorHandler()
            .get<ApiResponse<OrderDto>>(`api/order/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .pipe(map((response) => new Order(response.data)));
    }

    updateOrder(id: string, value: OrderUpdateValue, order: Order) {
        const params = new OrderUpdateValueDto(value, order);

        return this.httpClient
            .addServer()
            .skipErrorHandler()
            .put<ApiResponse<OrderDto>>(`api/order/${id}`, params)
            .pipe(map((response) => new Order(response.data)));
    }

    submitOrder(orderId: string, paymentId: string): Observable<OrderPayment> {
        const params = {
            provider: paymentId.split('-')[0],
            method: paymentId.split('-')[1],
        };

        return this.httpClient
            .addServer()
            .skipErrorHandler()
            .post<ApiResponse<OrderDto>>(`api/order/${orderId}/submit`, params)
            .pipe(
                map((response) => {
                    const order = new Order(response.data);
                    return <OrderPayment>order.payments.find((payment) => payment.id === order.transactions[0].paymentId);
                }),
                catchError((data: HttpErrorResponse | unknown) => {
                    throw new ErrorCreate(data);
                }),
            );
    }

    submitOrderTransaction(paymentId: string, transactionId: string): Observable<OrderPayment> {
        const params = {
            provider: paymentId.split('-')[0],
            method: paymentId.split('-')[1],
        };

        return this.httpClient
            .addServer()
            .skipErrorHandler()
            .post<ApiResponse<OrderPaymentDto>>(`api/transaction/${transactionId}/payment`, params)
            .pipe(
                map((response) => new OrderPayment(response.data)),
                catchError((data: HttpErrorResponse | unknown) => {
                    throw new ErrorCreate(data);
                }),
            );
    }
}
