import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AttachError } from '../models';

export const discountCodeActions = createActionGroup({
    source: 'Discount Code',
    events: {
        Attach: props<{ id: string; code: string }>(),
        'Attach Success': props<{ id: string }>(),
        'Attach Error': props<{ error: AttachError }>(),
        Detach: props<{ id: string }>(),
        'Detach Success': props<{ id: string }>(),
        'Detach Error': emptyProps(),
        Clear: emptyProps(),
    },
});
