import { createReducer, on } from '@ngrx/store';
import { orderActions } from '../actions/order.actions';
import { Order, OrderUpdateValue } from '../models';

export interface OrderState {
    order: Order | null;
    loaded: boolean | null;
    loadedErrorCode: number | null;
    loading: boolean;
    saved: string | null;
    savedErrorCode: number | null;
    saving: boolean;
    updated: string | null;
    updatedErrorCode: number | null;
    updating: boolean;
    updatesPending: OrderUpdateValue | null;
}

export const initialState: OrderState = {
    order: null,
    loaded: null,
    loadedErrorCode: null,
    loading: false,
    saving: false,
    saved: null,
    savedErrorCode: null,
    updated: null,
    updatedErrorCode: null,
    updating: false,
    updatesPending: null,
};

export const orderReducer = createReducer(
    initialState,
    /** CREATE */
    on(
        orderActions.createOrder,
        (state): OrderState => ({
            ...state,
            saving: true,
            saved: null,
        }),
    ),
    on(
        orderActions.createOrderSuccess,
        (state, action): OrderState => ({
            ...state,
            saving: false,
            saved: action.payload.id,
        }),
    ),
    on(
        orderActions.createOrderError,
        (state): OrderState => ({
            ...state,
            saving: false,
        }),
    ),
    /** GET */
    on(
        orderActions.getOrder,
        (state): OrderState => ({
            ...state,
            loading: true,
            order: null,
            loadedErrorCode: null,
        }),
    ),
    on(
        orderActions.getOrderSuccess,
        (state, action): OrderState => ({
            ...state,
            loading: false,
            loaded: true,
            order: action.payload,
        }),
    ),
    on(
        orderActions.getOrderError,
        (state, action): OrderState => ({
            ...state,
            loading: false,
            loaded: false,
            loadedErrorCode: action.error.status,
        }),
    ),
    /** UPDATE */
    on(
        orderActions.updateOrder,
        (state, action): OrderState => ({
            ...state,
            updatesPending: action.value,
        }),
    ),
    on(
        orderActions.updateOrderRun,
        (state): OrderState => ({
            ...state,
            updating: true,
            updated: null,
            updatedErrorCode: null,
        }),
    ),
    on(
        orderActions.updateOrderClear,
        (state): OrderState => ({
            ...state,
            updatesPending: null,
        }),
    ),
    on(
        orderActions.updateOrderSuccess,
        (state, action): OrderState => ({
            ...state,
            order: action.payload,
            updating: false,
            updated: action.payload.id,
        }),
    ),
    on(
        orderActions.updateOrderError,
        (state): OrderState => ({
            ...state,
            updating: false,
            updatedErrorCode: 1,
        }),
    ),
    /** SUBMIT */
    on(
        orderActions.submitOrder,
        (state): OrderState => ({
            ...state,
            saved: null,
            saving: true,
            savedErrorCode: null,
        }),
    ),
    on(
        orderActions.submitOrderSuccess,
        (state, action): OrderState => ({
            ...state,
            saved: action.payload.id,
            saving: false,
        }),
    ),
    on(
        orderActions.submitOrderError,
        (state): OrderState => ({
            ...state,
            saving: false,
            savedErrorCode: 0,
        }),
    ),
    // on(
    //     bookingActions.createBookingAction,
    //     (state): BookingState => ({
    //         ...state,
    //         saving: state.saving + 1,
    //     }),
    // ),
    // on(
    //     bookingActions.createBookingSuccessAction,
    //     (state, action): BookingState => ({
    //         ...state,
    //         saving: state.saving - 1,
    //         saved: action.payload.id,
    //     }),
    // ),
    // on(
    //     bookingActions.createBookingErrorAction,
    //     (state): BookingState => ({
    //         ...state,
    //         saving: state.saving - 1,
    //     }),
    // ),
    // on(
    //     bookingActions.updateBookingAction,
    //     (state): BookingState => ({
    //         ...state,
    //     }),
    // ),
    // on(
    //     bookingActions.updateBookingIncrementSavingCountAction,
    //     (state): BookingState => ({
    //         ...state,
    //         saving: state.saving + 1,
    //     }),
    // ),
    // on(
    //     bookingActions.updateBookingSuccessAction,
    //     (state, action): BookingState => ({
    //         ...state,
    //         saving: state.saving - 1,
    //         item: action.payload,
    //     }),
    // ),
    // on(
    //     bookingActions.updateBookingErrorAction,
    //     (state): BookingState => ({
    //         ...state,
    //         saving: state.saving - 1,
    //     }),
    // ),
    // on(
    //     bookingActions.updateBookingBlockAction,
    //     (state): BookingState => ({
    //         ...state,
    //         block: true,
    //     }),
    // ),
    on(
        orderActions.clear,
        (): OrderState => ({
            ...initialState,
        }),
    ),
);
