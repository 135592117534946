import { HttpErrorResponse } from '@angular/common/http';
import { Error } from './error';

export class ErrorCreate implements Error {
    code = 0;
    type: string | null = null;
    constructor(response: HttpErrorResponse | unknown) {
        if (response instanceof HttpErrorResponse) {
            this.code = response.status;
            switch (response.error.type) {
                case 'TimeLimitReachedException':
                    this.type = 'time-limit-reached';
                    break;
                case 'PassengerCountLimitReachedException':
                    this.type = 'passenger-count-limit-reached';
                    break;
            }
        }
    }
}
