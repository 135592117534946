import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Transit } from '@app/store/transits/models';
import { OrderDraftValue, OrderUpdateValue, Order, OrderPayment, Error } from '../models';

export const orderActions = createActionGroup({
    source: 'Order',
    events: {
        'Create Order': props<{ value: OrderDraftValue; transitFrom: Transit; transitTo?: Transit }>(),
        'Create Order Success': props<{ payload: { id: string } }>(),
        'Create Order Error': props<{ error: Error }>(),

        'Get Order': props<{ id: string }>(),
        'Get Order Success': props<{ payload: Order }>(),
        'Get Order Error': props<{ error: HttpErrorResponse }>(),

        'Get Order By Token': props<{ id: string; token: string }>(),
        'Get Order By Token Error': props<{ response: HttpErrorResponse }>(),

        'Update Order': props<{ id: string; value: OrderUpdateValue }>(),
        'Update Order Check': emptyProps(),
        'Update Order Run': emptyProps(),
        'Update Order Clear': emptyProps(),
        'Update Order Success': props<{ payload: Order }>(),
        'Update Order Error': emptyProps(),

        'Submit Order': props<{ orderId: string; paymentId: string; transactionId: string | null }>(),
        'Submit Order Success': props<{ payload: OrderPayment }>(),
        'Submit Order Error': props<{ error: Error }>(),

        Clear: emptyProps(),
    },
});
