import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrderState } from '../reducers/order.reducer';
import { ORDER_FEATURE_KEY } from '../keys';

const selectOrderState = createFeatureSelector<OrderState>(ORDER_FEATURE_KEY);

export const selectOrder = createSelector(selectOrderState, (state: OrderState) => state.order);

export const selectLoaded = createSelector(selectOrderState, (state: OrderState) => state.loaded);
export const selectLoading = createSelector(selectOrderState, (state: OrderState) => state.loading);
export const selectLoadedErrorCode = createSelector(selectOrderState, (state: OrderState) => state.loadedErrorCode);

export const selectSaved = createSelector(selectOrderState, (state: OrderState) => state.saved);
export const selectSaving = createSelector(selectOrderState, (state: OrderState) => state.saving);
export const selectSavedErrorCode = createSelector(selectOrderState, (state: OrderState) => state.savedErrorCode);

export const selectUpdated = createSelector(selectOrderState, (state: OrderState) => state.updated);
export const selectUpdating = createSelector(selectOrderState, (state: OrderState) => state.updating);
export const selectUpdatedErrorCode = createSelector(selectOrderState, (state: OrderState) => state.updatedErrorCode);

export const selectUpdatesPending = createSelector(selectOrderState, (state: OrderState) => state.updatesPending);
