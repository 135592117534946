import { Transit, TransitSector } from '@app/store/transits/models';
import { environment } from '@env/environment';
import { COUNTRY } from '@app/shared';
import { ACCURACY_TYPES } from '../enums';
import { DraftPositionValue, DraftRouteValue, OrderDraftValue } from './order-draft.value';

export class DraftValueDto {
    route: DraftRouteValueDto;
    startAddress: DraftAddressValueDto;
    stopAddress: DraftAddressValueDto;
    returnRoute: DraftRouteReturnValueDto | null = null;
    returnStartAddress: DraftAddressValueDto | null = null;
    returnStopAddress: DraftAddressValueDto | null = null;
    passengersCount: number;
    points_and_sectors = true;

    constructor(value: OrderDraftValue, transitFrom: Transit, transitTo?: Transit) {
        this.route = new DraftRouteValueDto(value, transitFrom.id);
        this.startAddress = new DraftAddressValueDto(value.from, transitFrom.sectorFrom);
        this.stopAddress = new DraftAddressValueDto(value.to, transitFrom.sectorTo);

        if (transitTo) {
            this.returnRoute = new DraftRouteReturnValueDto(value, transitTo.id);
            this.returnStartAddress = new DraftAddressValueDto(value.to, transitTo.sectorFrom);
            this.returnStopAddress = new DraftAddressValueDto(value.from, transitTo.sectorTo);
        }

        this.passengersCount = value.passengers.adults;
    }
}

class DraftRouteValueDto {
    routeId: string;
    fromLng: number;
    fromLat: number;
    toLng: number;
    toLat: number;
    limit: number;
    pointsAndSectors: boolean;

    constructor(value: OrderDraftValue, routeId: string) {
        this.routeId = routeId;
        this.fromLat = parseFloat(value.from.position.lat);
        this.fromLng = parseFloat(value.from.position.lng);
        this.toLat = parseFloat(value.to.position.lat);
        this.toLng = parseFloat(value.to.position.lng);
        this.limit = environment.booking.calendarAvailableDays + 1;
        this.pointsAndSectors = true;
    }
}

class DraftRouteReturnValueDto {
    routeId: string;
    fromLng: number;
    fromLat: number;
    toLng: number;
    toLat: number;
    limit: number;
    pointsAndSectors: boolean;

    constructor(value: OrderDraftValue, routeId: string) {
        this.routeId = routeId;
        this.fromLat = parseFloat(value.to.position.lat);
        this.fromLng = parseFloat(value.to.position.lng);
        this.toLat = parseFloat(value.from.position.lat);
        this.toLng = parseFloat(value.from.position.lng);
        this.limit = environment.booking.calendarAvailableDays + 2;
        this.pointsAndSectors = true;
    }
}

class DraftAddressValueDto {
    locationId: string;
    country = '';
    city = '';
    voivodeship = '';
    district = '';
    street = '';
    houseNumber = '';
    flatNumber = '';
    postalCode = '';
    accuracy: ACCURACY_TYPES;
    geoPosition: DraftAddressGeopositionValueDto | null = null;

    constructor(value: DraftRouteValue, sector: TransitSector) {
        if (sector.isPoint) {
            this.locationId = value.id;
            this.country = sector.address.country;
            this.city = sector.address.city;
            this.street = sector.address.street;
            this.houseNumber = sector.address.number;
            this.postalCode = sector.address.postalCode;
            this.accuracy = value.accuracy;
            if (sector.address.position.lat && sector.address.position.lng) {
                this.geoPosition = new DraftAddressGeopositionValueDto({
                    lat: sector.address.position.lat,
                    lng: sector.address.position.lng,
                });
            }
        } else {
            this.locationId = value.id;
            this.country = COUNTRY.con3to2(value.country);
            this.city = value.city;
            this.street = value.street;
            this.houseNumber = value.number;
            this.postalCode = value.postalCode;
            this.accuracy = value.accuracy;
            this.geoPosition = new DraftAddressGeopositionValueDto(value.position);
        }
    }
}

class DraftAddressGeopositionValueDto {
    longitude: number;
    latitude: number;
    constructor(value: DraftPositionValue) {
        this.latitude = parseFloat(value.lat);
        this.longitude = parseFloat(value.lng);
    }
}
