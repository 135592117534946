import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { OrderFacade } from '../facades/order.facade';

export function orderCleanup<T>(): CanDeactivateFn<T> {
    return () => {
        const orderFacade = inject(OrderFacade);
        orderFacade.clear();

        return true;
    };
}
